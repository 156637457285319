import Form from '@searchtrends/webform-processor';

let contactforms = {};
let forms = document.querySelectorAll('form[method="post"]:not(.no-ajax)');
forms.forEach(function (cf, index) {
    contactforms[`sendform_${index}`] = new Form(cf, index).onlyOnce();

    cf.addEventListener('submit', function (e) {
        e.preventDefault();

        contactforms[`sendform_${index}`].post(cf.action)
            .then(response => { console.log(response); });
    });
});
